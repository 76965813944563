import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/v2/Button';

const useStyles = makeStyles(theme => ({
  root: {
    background: `url(${require('../../assets/images/landing1.png')})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      background: `url(${require('../../assets/images/landing1_m.png')})`,
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  logo: {
    width: 240,
    [theme.breakpoints.down('sm')]: {
      width: 160
    },
  }
}));

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Box height="100vh" className={classes.root} pt={6}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={require('../../assets/images/mmorlogo.png')} className={classes.logo} />
        <Box my={4} px={4} textAlign="center">
          <Typography variant="h5" component="h1" color="textPrimary">เรามีแพทย์ผู้เชี่ยวชาญทุกแผนก พร้อมให้คำปรึกษา ไม่ต้องเดินทางให้ลำบาก สบายใจเหมือนมีหมออยู่ข้างตัว</Typography>
        </Box>
        <Box my={1}>
          <Typography variant="h4" component="h1" color="textPrimary">คลิกดาวน์โหลดเลย</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <a href="http://bit.ly/45ydIj0"><img src={require('../../assets/images/Download_on_the_App_Store_Badge.svg')} width={160} /></a>
          <a href="http://bit.ly/45ydIj0"><img src={require('../../assets/images/google-play-badge.png')} width={180} /></a>
        </Box>
      </Box>
      <Box position="fixed" bottom={4} right={4}>
        <a href="http://bit.ly/45ydIj0"><img src={require('../../assets/images/qrcode.png')} width={170} /></a>
      </Box>
    </Box>
  )
}

export default IndexPage